export const fields = [
    { key: 'index', label: "No", _style:'min-width:50px'},
    { key: 'select', label: "", _style:'min-width:50px' },
    { key: 'nama_satker', label: "OPD", _style:'min-width:100px' },
    { key: 'nama_paket', label: "Nama Paket", _style:'min-width:100px' },
    { key: "pagu", label: "Pagu", _style:'min-width:50px'},
    { key: 'hps',label: "Hps", _style:'min-width:100px;' },
    { key: 'sumber_dana', label: "Sumber Dana", _style:'min-width:100px;' },
    { key: 'jenis_pengadaan', label: "Jenis Pengadaan", _style:'min-width:100px;' },
    { key: 'mtd_pemilihan', label: "Mtd Pemlilihan", _style:'min-width:100px;' },
    { key: 'tgl_penetapan_pemenang', label: "Tgl Penetapan Pemenang", _style:'min-width:100px;' },
]
