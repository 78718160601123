import { render, staticRenderFns } from "./tender-selesai.vue?vue&type=template&id=3d23eb45"
import script from "./tender-selesai.vue?vue&type=script&lang=js"
export * from "./tender-selesai.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports