<template>
  <div>
    <h3>SPSE - Tender Selesai</h3>
    <br />
    <CCard>
      <CCardBody>
        <CDataTable
          class="table-striped"
          :items="computedItems"
          :fields="fields"
          sorter
          pagination
          columnFilter
          tableFilter
          itemsPerPageSelect
          itemsPerPage=10
        >
        <template #select="{ item }">
          <td class="py-2">
            <input 
                type="checkbox"
                @change="check(item)"
                v-model="item.select"
              />
          </td>
        </template>
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import * as data from "../../model/tender-selesai";

export default {
  data() {
    return {
      fields: [],
      items: [],
      selectedItems: [],
      isSelectedAll: false,
      selectedAction: 0,
      page: 1,
      total: 0,
      user: JSON.parse(localStorage.getItem("user")),
      params: {
        sorttype: "desc",
        sortby: "id",
        row: 50,
        page: 1,
        type: [0, 1],
        keyword: "",
      },
      isSearching: false,
      searchOn: '',
      _progress: {},
      exist_progress: false
    };
  },
  methods: {
    getData() {
      var loading = this.$loading.show();
      this.$store
        .dispatch("tender_selesai/getTenderSelesai", this.params)
        .then((resp) => {
   
          this.items = resp;
          this.total = 0;

          // khusus untuk checkbox
          this.selectedItems = [];
          this.items.forEach(element => {
            if (this.isSelectedAll) {
              element.select = true;
              this.selectedItems.push(element.id);
            } else {
              element.select = false;
            }
          });
          loading.hide();
        })
        .catch((e) => {
          this.$toast.error("gagal mengambil data  \n", e);
          loading.hide();
        });
    },
    addModal() {
      this.isUpdate = false;
      this.createModal = true;
      this.imageList = [];
      this.form = {};
      this.getKecamatan();
    },
    addModalImport() {
      this.createModalImport = true;
    },
    pagination(page) {
      this.page = page;
      this.params.page = page;
      this.getData();
    },
    uploadAfter() {
      this.$refs.uploadFieldAfter.click();
    },
    selectFileAfter(event) {
      this.file = event.target.files[0];
      var loading = this.$loading.show();
      uploadImage(this.file)
        .then((resp) => {
          this.imageListAfter.push(resp);
          loading.hide();
          alert("File berhasil diupload !!");
        })
        .catch((e) => {
          loading.hide();
          alert("Terjadi kesalahan !! | " + e);
        });
    },
    uploadProcess() {
      this.$refs.uploadFieldProcess.click();
    },
    importData($event) {
      console.log($event, "event");
      this.file = event.target.files[0];
      var loading = this.$loading.show();
      
    },
    selectFileProcess(event) {
      this.file = event.target.files[0];
      var loading = this.$loading.show();
      uploadImage(this.file)
        .then((resp) => {
          this.imageListProcess.push(resp);
          loading.hide();
          alert("File berhasil diupload !!");
        })
        .catch((e) => {
          loading.hide();
          alert("Terjadi kesalahan !! | " + e);
        });
    },
    uploadBefore() {
      this.$refs.uploadFieldBefore.click();
    },
    selectFileBefore(event) {
      this.file = event.target.files[0];
      var loading = this.$loading.show();
      uploadImage(this.file)
        .then((resp) => {
          this.imageListBefore.push(resp);
          loading.hide();
          alert("File berhasil diupload !!");
        })
        .catch((e) => {
          loading.hide();
          alert("Terjadi kesalahan !! | " + e);
        });
    },
    deleteImageBefore(state, index) {
      this.imageListBefore.splice(index, 1);
    },
    deleteImageProcess(state, index) {
      this.imageListProcess.splice(index, 1);
    },
    deleteImageAfter(state, index) {
      this.imageListAfter.splice(index, 1);
    },
    extractGallery(item) {
      let data = [];
      item.forEach(element => {
        data.push(element.image);
      });
      return data;
    },
    check(item) {
      if (item.select) {
        this.selectedItems.push(item.id);
      } else {
        const index = this.selectedItems.indexOf(item.id);
        this.selectedItems.splice(index, 1);
      }
    },
    checkAll() {
      this.getData();
    },
    changeActionSelected() {
      console.log(this.selectedAction)
      switch (Number(this.selectedAction)) {
        case 1:
          console.log('deleted')
          this.deleteSelected('delete');
          break;
        case 2:
          console.log('export excel')
          this.exportExcel('export_excel');
          break;
        case 3:
          console.log('export pdf')
          this.exportPDF();
          break;
      }
    },
    deleteSelected(action) {
      var loading = this.$loading.show();
      this.$store
        .dispatch("paket_pekerjaan/selectedAction", 
        {
          action: action,
          data: this.selectedItems,
        })
        .then((resp) => {
          this.$toast.success("Item Selected Berhasil diproses");
          loading.hide();
          this.createModal = false;
          this.form = {
          };
          this.getData();
        })
        .catch((e) => {
          this.$toast.error("gagal mengambil data  \n", e);
          loading.hide();
        });
    },
    openModalExcel() {
      this.exportModal = true;
      this.exportType = "Export Excel";
    },
    openModalPDF() {
      this.exportModal = true;
      this.exportType = "Export PDF";
    },
    exportExcel(action) {
      var loading = this.$loading.show();
      this.$store
       .dispatch("paket_pekerjaan/exportReport", {
          data: this.selectedItems,
        })
        .then((resp) => {
          loading.hide();
          FileSaver.saveAs(
            resp.data,
            "Laporan_Harian_"
          );
          this.exportModal = false;
          this.exportDataParams = {};
        })
        .catch((e) => {
          this.$toast.error(e);
          loading.hide();
        });
    },
    exportPDF() {
      this.$router.push({ name: "PaketPekerjaanExportPDF", query: { data: this.selectedItems } });
    },
    progress(data) {
      this._progress = data;
      this.form.paket_pekerjaan_progress = this._progress.paket_pekerjaan_progress;
      if (this._progress.paket_pekerjaan_progress.length > 0) {
        this.exist_progress = true
      } else {
        this.exist_progress = false
      }
      this.createModalProgress = true;
    },
    addDetail() {
      this.form.paket_pekerjaan_progress.push({
        paket_pekerjaan_id: this._progress.id,
        kegiatan: "",
        minggu_ke: 0,
        progress_rencana: 0,
        kumulatif_rencana: 0,
        progress_realisasi: 0,
        kumulatif_realisasi: 0,
        deviasi: 0
      })
    },
    deleteDetail(index) {
     this.form.paket_pekerjaan_progress.splice(index, 1);
    },
    cancelProgress() {
      this.createModalProgress = false;
      this.form.paket_pekerjaan_processes = [];
    },
    updateProgress() {
      var loading = this.$loading.show();
      this.$store
        .dispatch("paket_pekerjaan/progress", 
        this.form)
        .then((resp) => {
          this.$toast.success("Item Selected Berhasil diproses");
          loading.hide();
          this.createModalProgress = false;
          this.form = {
            paket_pekerjaan_progress: []
          };
          this.getData();
        })
        .catch((e) => {
          this.$toast.error("gagal simpan data  \n", e);
          loading.hide();
        });
    },
    hitungKumulatif(_index) {
      let kumulatif = 0;
      this.form.paket_pekerjaan_progress.forEach((element, index) => {
        if (index <= _index) {
          kumulatif += parseInt(element.progress_rencana);
        }
      })
      this.form.paket_pekerjaan_progress[_index].kumulatif_rencana = kumulatif;
      return this.form.paket_pekerjaan_progress[_index].kumulatif_rencana;
    },
    hitungKumulatifRealisasi(_index) {
      let kumulatif = 0;
      this.form.paket_pekerjaan_progress.forEach((element, index) => {
        if (index <= _index) {
          kumulatif += parseInt(element.progress_realisasi);
        }
      })
      this.form.paket_pekerjaan_progress[_index].kumulatif_realisasi = kumulatif;
      return this.form.paket_pekerjaan_progress[_index].kumulatif_realisasi;
    },
    hitungDeviasi(_index) {
      this.form.paket_pekerjaan_progress[_index].deviasi = this.form.paket_pekerjaan_progress[_index].kumulatif_realisasi - this.form.paket_pekerjaan_progress[_index].kumulatif_rencana;
      return this.form.paket_pekerjaan_progress[_index].deviasi;
    }
  },
  computed: {
    computedItems() {  
      return this.items.map((item, index) => {
        return {
          index: index+1 + '.',
          ...item,
          pagu: item.pagu.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          hps: item.hps.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          tgl_penetapan_pemenang: item.tgl_penetapan_pemenang.slice(0, 10),
        };
      });
    },
    
  },
  mounted() {
    this.getData();
  },

  created() {
    this.fields =
      this.user.role.name.toLowerCase() == "admin"
        ? data.fields
        : data.fields_no_action;
  },
};
</script>